import { PROMOBOT_OMS, PROMOBOT_SNILS, promobotFieldMap } from '@/vue_components/doctor_schedules/consts'
import { cloneDeep } from 'lodash'
import { getDocumentsMap } from '@/helpers/Promobot/clientLegacyHook/clientLegacyHookLogic'

const MALE = 1
const FEMALE = 2

const specialFields = [
  'birthdate',
  'issueDate',
  'sex',
]

export const reformatDate = (value) =>
  moment(value, 'DD.MM.YYYY').format(Utils.baseDateFormat).toString()

export const reformatSex = (value) =>
  value
    ? value?.toLowerCase()?.includes('жен') || value?.toLowerCase() === 'ж.' ? FEMALE : MALE
    : null

/**
 * @param {Object} form
 * @param {String} type
 * @param {Array} documentTypes
 */
const fillDocumentType = (form = {}, type = '', documentTypes = []) => {
  const foundedDocumentType = documentTypes
    .find((documentType) => documentType.title === getDocumentsMap()[type])

  if (!foundedDocumentType) { return }
  form.documentType = foundedDocumentType.id
}

/**
 * @param {Object} form
 * @param {DocumentData[]} data
 */
const fillFormData = (form = {}, data = []) => {
  const _data = data || []
  _data.forEach(
    /** @param {DocumentData} field */
    (field) => {
      if (!field.name || !field.is_accepted) { return }
      const mappedName = promobotFieldMap[field.name]
      if (!mappedName) { return }

      if (specialFields.includes(mappedName)) {
        if (mappedName === 'birthdate' || mappedName === 'issueDate') {
          form[mappedName] = reformatDate(field.value)
        }

        if (mappedName === 'sex') {
          form.sex = reformatSex(field.value)
        }

        return
      }

      form[mappedName] = field.value
    })
}

/**
 * @param documentType
 * @param oldData
 * @return {DocumentData[]}
 */
export const processingData = (documentType, oldData) => {
  const data = cloneDeep(oldData)

  if (PROMOBOT_SNILS.includes(documentType)) {
    const snilsNumberField = data.find(({ name }) => name === 'number')
    if (snilsNumberField) { data.push({ ...snilsNumberField, name: 'snils' }) }
  }

  if (PROMOBOT_OMS.includes(documentType)) {
    const omsNumberField = data.find(({ name }) => name === 'number')
    if (omsNumberField) { data.push({ ...omsNumberField, name: 'oms' }) }
  }

  return data
}

/**
 * @param {Object} form
 * @param {DocumentData[]} data
 * @param {{ type: string, documentTypes: [] }}extra
 */
export const fillClientFormByPromobotData = (form = {}, data = [], extra = {}) => {
  fillDocumentType(form, extra.type, extra.documentTypes)

  const processedData = processingData(extra.type, data)
  fillFormData(form, processedData)
}
