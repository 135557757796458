import {
  promobotDocumentTypeToMedodsDocumentTypeMap,
  promobotDocumentTypeToNSITypeMap,
} from '@/vue_components/doctor_schedules/consts'
import { promobotFieldMapLegacy } from '@/helpers/Promobot/clientLegacyHook/promobotFieldMapLegacy'
import { processingData, reformatSex } from '@/vue_components/doctor_schedules/promobotHelpers'

const LEGAL_REP_SELECTOR = '#legal_representative_modal'
const ID_CARD_TITLE_SELECTOR = '#client_id_card_id_card_title_id'
const NSI_ID_CARD_TITLE_SELECTOR = '#client_id_card_nsi_document_id'
const SPECIAL_FIELDS = ['#client_sex_id']

/* Глобальный 🩼 */
let rootSelector = document

const extractIdCardTitleSelector = () => {
  return gon.application.nsi.idCardTypesDictionary
    ? NSI_ID_CARD_TITLE_SELECTOR
    : ID_CARD_TITLE_SELECTOR
}

const extractIdCardTitleOptions = () => {
  const selector = extractIdCardTitleSelector()

  return rootSelector.querySelector(selector).querySelectorAll('option')
}

const setValue = (selectorId, value) => {
  const selector = rootSelector.querySelector(selectorId)
  if (!selector) {
    return console.info('selector not found', selectorId)
  }
  selector.value = value
  selector.dispatchEvent(new Event('change'))
}

export const getDocumentsMap = () => {
  return gon.application.nsi.idCardTypesDictionary
    ? promobotDocumentTypeToNSITypeMap
    : promobotDocumentTypeToMedodsDocumentTypeMap
}

const fillDocumentType = (type) => {
  const idCardTitle = getDocumentsMap()[type]
  if (!idCardTitle) { return }

  const documents = Array.from(
    extractIdCardTitleOptions()
  )

  const mappedDocuments = Utils.arrayToMap(
    documents
      .map((opt) =>
        ({ value: opt.value, title: opt.innerText })),
    'title'
  )

  const value = mappedDocuments[idCardTitle]?.value
  if (!value) { return }

  setValue(extractIdCardTitleSelector(), value)
}

/**
 * @param {DocumentData[]} fields
 */
const fillForm = (fields) => {
  fields.forEach((field) => {
    if (!field.name || !field.is_accepted) { return }

    const fieldId = promobotFieldMapLegacy[field.name]
    if (!fieldId) { return }

    if (SPECIAL_FIELDS.includes(fieldId)) {
      if (fieldId === '#client_sex_id') {
        setValue(fieldId, reformatSex(field.value))
      }

      return
    }

    setValue(fieldId, field.value)
  })
}

const fillData = (type, fields, isLegalModal) => {
  rootSelector = isLegalModal
    ? document.querySelector(LEGAL_REP_SELECTOR)
    : document

  fillDocumentType(type)
  fillForm(fields)
}

export const onPromobotDataScanned = (_, data) => {
  const legalRepSelector = document.querySelector(LEGAL_REP_SELECTOR)
  const isLegalModal = legalRepSelector?.style?.display

  const type = data.type || data.doc_type
  const fields = processingData(type, data.data || data.results)

  fillData(type, fields, isLegalModal)
}
